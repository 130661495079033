import Cookie from "js-cookie"
export default {
    state: {
        token: '',
        languageFlag: true,
        adminStatus:''
    },
    mutations: {
        setToken(state, val) {
            state.token = val
            Cookie.set('token', val)
        },
        clearToken(state) {
            state.token = ''
            Cookie.remove('token')
        },
        getToken(state) {
            state.token = state.token || Cookie.get('token')
        },
        setAdminStatus(state, val) {
            state.adminStatus = val
            Cookie.set('adminStatus', val)
        },
        clearAdminStatus(state) {
            state.adminStatus = ''
            Cookie.remove('adminStatus')
        },
        getAdminStatus(state) {
            state.adminStatus = state.adminStatus || Cookie.get('adminStatus')
        },
        changeLanguage(state) {
            state.languageFlag = !state.languageFlag
            console.log(state.languageFlag);
        }
    }
}