export default {
    state: {
        isCollapse: false,
        breadcrumb: [
            {
                path: "/",
                name: "home",
                label: "首页",
                icon: "s-home",
                url: "Home/Home",
            },
        ]
    },
    mutations: {
        valueCollapse(state) {
            state.isCollapse = !state.isCollapse
        },
        dynamicBread(state, val) {
            if (val.name == "home") {
            } else {
                let indexNum = state.breadcrumb.indexOf(val)
                if (indexNum == -1) {
                    state.breadcrumb.push(val)
                    console.log(state.breadcrumb);
                }
            }
        },
        delBread(state, val) {
            let flag = state.breadcrumb.indexOf(val)
            state.breadcrumb.splice(flag, 1)
        }
    },
}