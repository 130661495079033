import axios from "./axios";
export const getMenu = (param) => {
    return axios.request({
        url: '/manage/video/recommendList',
        method: 'get',
        data: param
    })
}
export const login = (param) => {
    return axios.request({
        url: '/security/login',
        method: 'POST',
        data: param,
        // withCredentials: false
    })
}
export const logout = () => {
    return axios.request({
        url: '/security/logout',
        method: 'get',
    })
}
export const getData = () => {
    return axios.request({
        url: '/home/getData',
    })
}
export const getSnNum = () => {
    return axios.request({
        url: '/manage/lesvr/getSnNum '
    })
}
export const getTopThree = (param) => {
    return axios.request({
        url: '/manage/lesvr/generAndConsum ',
        method: 'POST',
        data: param
    })
}
export const getOnlineSnByArea = (param) => {
    return axios.request({
        url: '/manage/lesvr/queryOnlineSnByArea',
        method: 'POST',
        data: param
    })
}
export const getArea = (param) => {
    return axios.request({
        url: '/manage/lesvr/queryArea ',
    })
}
export const getHourData = (param) => {
    return axios.request({
        url: '/manage/lesvr/getHourAdd',
        method: 'POST',
        data: param
    })
}
export const getDayData = (param) => {
    return axios.request({
        url: '/manage/lesvr/getAllDayPoint',
        method: 'POST',
        data: param
    })
}
export const getMonthData = (param) => {
    return axios.request({
        url: '/manage/lesvr/getMonthPoint',
        method: 'POST',
        data: param
    })
}
export const getYearData = (param) => {
    return axios.request({
        url: '/manage/lesvr/getYearPoint',
        method: 'POST',
        data: param
    })
}
export const getEqualsData = (param) => {
    return axios.request({
        url: '/manage/lesvr/queryEqualsData',
        method: 'POST',
        data: param
    })
}
export const getManageUserList = (param) => {
    return axios.request({
        url: '/manage/lesvr/getManageUserList',
        method: 'POST',
        data: param
    })
}
export const addSn = (param) => {
    return axios.request({
        url: '/manage/lesvr/addSn',
        method: 'POST',
        data: param
    })
}
export const getAdminUserSnList = (param) => {
    return axios.request({
        url: '/manage/lesvr/getAdminUserSnList',
        method: 'POST',
        data: param
    })
}
export const createMainAccount = (param) => {
    return axios.request({
        url: '//manage/user/create',
        method: 'POST',
        data: param
    })
}
export const deleteSn = (param) => {
    return axios.request({
        url: '/manage/lesvr/deleteSn',
        method: 'POST',
        data: param
    })
}
export const getFailureSnList = (param) => {
    return axios.request({
        url: '/manage/lesvr/getErrorLog',
        method: 'post',
        data: param
    })
}
export const getDeviceSnList = (param) => {
    return axios.request({
        url: '/manage/lesvr/getUserSnList',
        method: 'post',
        data: param
    })
}
export const delectUserDevice = (param) => {
    return axios.request({
        url: '/manage/lesvr/delUserDevice',
        method: 'post',
        data: param
    })
}

export const getDeviceHourData = (param) => {
    return axios.request({
        url: '/manage/lesvr/getAllDayData',
        method: 'post',
        data: param
    })
}

export const getDeviceDayData = (param) => {
    return axios.request({
        url: '/manage/lesvr/getMonthData',
        method: 'post',
        data: param
    })
}
export const getDeviceMonthData = (param) => {
    return axios.request({
        url: '/manage/lesvr/getYearData',
        method: 'POST',
        data: param
    })
}
export const getDeviceYearData = (param) => {
    return axios.request({
        url: '/manage/lesvr/getHistoryYearData',
        method: 'POST',
        data: param
    })
}

export const getDeviceSevenDayData = (param) => {
    return axios.request({
        url: '/manage/lesvr/senvenDay',
        method: 'POST',
        data: param
    })
}

export const getDeviceTodayPvData = (param) => {
    return axios.request({
        url: '/manage/lesvr/pvVoltage',
        method: 'POST',
        data: param
    })
}

export const getDeviceTodayBatteryData = (param) => {
    return axios.request({
        url: '/manage/lesvr/batSoc',
        method: 'POST',
        data: param
    })
}

export const getDeviceModelList = (param) => {
    return axios.request({
        url: '/manage/lesvr/deviceTypeSettingList',
        method: 'post',
        data: param
    })
}

export const getDeviceSpeModelList = (param) => {
    return axios.request({
        url: '/manage/lesvr/moduleVersion',
        method: 'post',
        data: param
    })
}

export const createDeviceModel = (param) => {
    return axios.request({
        url: '/manage/lesvr/createDeviceTypeSetting',
        method: 'post',
        data: param
    })
}

export const updetaDeviceModel = (param) => {
    return axios.request({
        url: '/manage/lesvr/updateDeviceTypeSetting',
        method: 'post',
        data: param
    })
}

export const updetaDeviceSpeModel = (param) => {
    return axios.request({
        url: '/manage/lesvr/updateModuleVersion',
        method: 'post',
        data: param
    })
}

export const uploadSpeBinFile = (param) => {
    return axios.request({
        url: '/user/uploadModuleFile',
        method: 'post',
        data: param
    })
}

export const deleteDeviceModel = (param) => {
    return axios.request({
        url: '/manage/lesvr/delDeviceTypeSetting',
        method: 'post',
        data: param
    })
}

export const getDeviceModelFirmwareList = (param) => {
    return axios.request({
        url: '/manage/lesvr/deviceVersionList',
        method: 'post',
        data: param
    })
}

export const uploadBinFile = (param) => {
    return axios.request({
        url: '/user/uploadBinFile',
        method: 'post',
        data: param
    })
}

export const createDeviceFirmware = (param) => {
    return axios.request({
        url: '/manage/lesvr/createDeviceVersion',
        method: 'post',
        'Content-type' : 'multipart/form-data',
        data: param
    })
}

export const updateDeviceFirmware = (param) => {
    return axios.request({
        url: '/manage/lesvr/updateDeviceVersion',
        method: 'post',
        data: param
    })
}

export const deleteDeviceFirmware = (param) => {
    return axios.request({
        url: '/manage/lesvr/delDeviceVersion',
        method: 'post',
        data: param
    })
}

export const postDebugDataData = (param) => {
    return axios.request({
        url: '/manage/lesvr/saveMsg',
        method: 'get',
        params: param
    })
}

export const getSingleOrderData = (param) => {
    return axios.request({
        url: '/manage/lesvr/parseReadStr',
        method: 'get',
        params: param
    })
}

export const getMultipleOrderData = (param) => {
    return axios.request({
        url: '/manage/lesvr/parseModifyStr',
        method: 'get',
        params: param
    })
}

export const getMasterSlaveConnectionList = (param) => {
    return axios.request({
        url: '/manage/lesvr/getMasterSlaveInfo',
        method: 'get',
        params: param
    })
}

export const getDeviceUpdateVersionList = (param) => {
    return axios.request({
        url: '/manage/lesvr/deviceVersionListByDtName',
        method: 'get',
        params: param
    })
}

export const getAdminUserPowerList = (param) => {
    return axios.request({
        url: '/manage/lesvr/getUserPermission',
        method: 'get',
        params: param
    })
}

export const OpenAdminUserPower = (param) => {
    return axios.request({
        url: '/manage/lesvr/createUserPermission',
        method: 'get',
        params: param
    })
}

export const CloseAdminUserPower = (param) => {
    return axios.request({
        url: '/manage/lesvr/removeUserPermission',
        method: 'get',
        params: param
    })
}

export const getDeviceTypeImgList = (param) => {
    return axios.request({
        url: '/manage/lesvr/getDeviceTypeImg',
        method: 'get',
        params: param
    })
}


export const getFastBtnlList = (param) => {
    return axios.request({
        url: '/manage/device/deviceQuickSettingList',
        method: 'get',
        params: param
    })
}

export const createFastBtn = (param) => {
    return axios.request({
        url: '/manage/device/addDeviceQuickSetting',
        method: 'post',
        data: param
    })
}

export const updateFastBtn = (param) => {
    return axios.request({
        url: '/manage/device/updateDeviceQuickSetting',
        method: 'post',
        data: param
    })
}

export const deleteFastBtn = (param) => {
    return axios.request({
        url: '/manage/device/delDeviceQuickSetting',
        method: 'post',
        data: param
    })
}

export const getDealarUserList = (param) => {
    return axios.request({
        url: '/manage/lesvr/userManage',
        method: 'get',
        params: param
    })
}

export const getDealarDeviceList = (param) => {
    return axios.request({
        url: '/manage/lesvr/manageDeviceInfo',
        method: 'get',
        params: param
    })
}

export const getSearchDevice = (param) => {
    return axios.request({
        url: '/manage/lesvr/manageAddDevice',
        method: 'get',
        params: param
    })
}

export const postDeviceListToServer = (param) => {
    return axios.request({
        url: '/manage/lesvr/createManageDevice',
        method: 'post',
        data: param
    })
}