import axios from "axios";
import config from "../config"
import router from '../router'

// 判断当前环境，从而选择不同基础url地址
// 代理
// const baseUrl = '/api'
// 测试
//const baseUrl = 'http://lehttest.suntcn.com:9306/'
// 正式
//const baseUrl = 'http://lesvr.suntcn.com:8101/'
const baseUrl = 'https://lehtapi.suntcn.com/'
// process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro

class HttpRequest {
    constructor(baseUrl) {
        this.baseUrl = baseUrl
    }
    getInsideConfig() {
        const config = {
            baseUrl: this.baseUrl,
            headers: {
                'Content-Type': 'multipart/form-data',
                'dataType': 'json',
            },
            withCredentials: true
        }
        return config
    }
    interceptors(instance) {
        // 添加请求拦截器
        instance.interceptors.request.use(function (config) {
            // 在发送请求之前做些什么
            return config;
        }, function (error) {
            // 对请求错误做些什么
            return Promise.reject(error);
        });

        // 添加响应拦截器
        instance.interceptors.response.use(function (response) {
            console.log('response:' + response);
            if (response.data.returnValue == 1000) {
                router.push('/login')
            }
            // 对响应数据做点什么
            return response;
        }, function (error) {
            // 对响应错误做点什么
            console.log('error:' + error);
            router.push('/login')
            return Promise.reject(error);
        });
    }
    request(options) {
        
        const instance = axios.create()
        options = { ...this.getInsideConfig(), ...options }
        options.url = options.baseUrl + options.url
        this.interceptors(instance)
        return instance(options)
    }
}

export default new HttpRequest(baseUrl)