import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: "/home",  //重定向到第一个子路由
    component: function () {
      return import('../views/indexView.vue')
    },
    children: [
      { path: 'home', name: 'home', component: HomeView },
      {
        path: 'log', name: 'log', component: function () {
          return import('../views/logView.vue')
        }
      },
      {
        path: 'failure', name: 'failure', component: function () {
          return import('../views/failureView.vue')
        }
      },
      {
        path: 'deviceList', name: 'deviceList', component: function () {
          return import('../views/deviceList.vue')
        }
      },
      {
        path: 'deviceModel', name: 'deviceModel', component: function () {
          return import('../views/deviceModel.vue')
        }
      },
      {
        path: 'deviceSet', name: 'deviceSet', component: function () {
          return import('../views/deviceSet.vue')
        }
      },
      {
        path: 'devicePic', name: 'devicePic', component: function () {
          return import('../views/devicePic.vue')
        }
      },
      {
        path: 'fastBtn', name: 'fastBtn', component: function () {
          return import('../views/fastBtn.vue')
        }
      },
      {
        path: 'dealerManage', name: 'fastBtn', component: function () {
          return import('../views/dealerManage.vue')
        }
      },
      // {
      //   path: 'device', name: 'device', component: function () {
      //     return import('../views/deviceAssignment.vue')
      //   }
      // },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: function () {
      return import('../views/login.vue')
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
