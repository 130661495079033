import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import http from 'axios'
import * as  echarts from 'echarts'
import BaiduMap from 'vue-baidu-map'
import {getChildrenPath} from './router/routerUtil'

Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false
Vue.use( BaiduMap, { ak : '9DFWo871j3EHc3rqVZ9fsXNTFda6SiMe' } )
Vue.use(ElementUI)
Vue.prototype.$http = http

router.beforeEach(function (to, from, next) {
  store.commit('getToken')
  const token = store.state.user.token
  console.log(token);
  if (!token && to.name !== 'login') {
    next({ name: 'login' })
  } else {
    next()
  }
})
store.commit('getAdminStatus')
const adminStatus = store.state.user.adminStatus
if (adminStatus == 1) {
  getChildrenPath()
}

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
