<template>
<div class='wrapper'>

	<!-- 左边视图 -->

	<div ref='initLeft' style="width: 23.5vw;height: 81.7vh;">
		<div class="leftBox">
			<div class="leftBoxTitle" style="margin-bottom: 1.4vh;">
				<div>{{$store.state.user.languageFlag ? '我的账户' : 'Account'}}</div>
			</div>
			<div ref="initVariety" style="width: 100%;height: 18vh;"></div>
		</div>
		<div class="leftBox">
			<div class="leftBoxTitle">
				<div>{{$store.state.user.languageFlag ? '能量统计数据' : 'Energy Data'}}</div>
				<div style="white-space: nowrap;">
					<span :class="energyStatisticsType == 1 ? 'dateGetBtn': 'dateLostBtn'" @click="changeEnergyType(1)">{{$store.state.user.languageFlag ? '日' : 'D'}}</span>
					<span :class="energyStatisticsType == 1 ? 'dateLostBtn': 'dateGetBtn'" @click="changeEnergyType(2)">{{$store.state.user.languageFlag ? '月' : 'M'}}</span>
				</div>
			</div>
			<div style="width: 100%;height: 18vh;margin-top: 1.4vh;position: relative;">
				
				<div ref='initLeftBar' style="height: 100%;width: 50%;display: inline-block;">
				</div>
				<div ref='initRightBar' style="height: 100%;width: 50%;display: inline-block;"></div>
			</div>
		</div>
		<div class="leftBox" style="margin-bottom: 0;">
			<div class="leftBoxTitle">
				<div>{{$store.state.user.languageFlag ? 'Top 3 国家' : 'Top 3 Countries'}}</div>
				<div style="white-space: nowrap;">
					<span :class="top3Type == 1 ? 'dateGetBtn': 'dateLostBtn'" @click="changeType(1)">{{$store.state.user.languageFlag ? '日' : 'D'}}</span>
					<span :class="top3Type == 1 ? 'dateLostBtn': 'dateGetBtn'" @click="changeType(2)">{{$store.state.user.languageFlag ? '月' : 'M'}}</span>
				</div>
			</div>
			<div class="topTable">
				<div class="tableTitle">
					<div style="flex: 1;text-align: left;margin-left: 1.5vw;">{{$store.state.user.languageFlag ? '国家' : 'Country'}}</div>
					<div style="flex: 2;">{{$store.state.user.languageFlag ? '发电量' : 'Generation'}}</div>
					<div style="flex: 1.4;">{{$store.state.user.languageFlag ? '耗电量' : 'Consumption'}}</div>
				</div>
				<div class="tableline">
					<img src="../assets/img/no1.png" alt="" class="topIcon">
					<div style="flex: 1;text-align: left;margin-left: 1.5vw;">{{$store.state.user.languageFlag ? top3Value[0].contry: top3Value[0].contryEn}}</div>
					<div style="flex: 2;">{{ top3Value[0].gener }}kW·h</div>
					<div style="flex: 1.4;">{{ top3Value[0].consumer }}kW·h</div>
				</div>
				<div class="tableline">
					<img src="../assets/img/no2.png" alt="" class="topIcon">
					<div style="flex: 1;text-align: left;margin-left: 1.5vw;">{{$store.state.user.languageFlag ? top3Value[1].contry: top3Value[1].contryEn}}</div>
					<div style="flex: 2;">{{ top3Value[1].gener }}kW·h</div>
					<div style="flex: 1.4;">{{ top3Value[1].consumer }}kW·h</div>
				</div>
				<div class="tableline">
					<img src="../assets/img/no3.png" alt="" class="topIcon">
					<div style="flex: 1;text-align: left;margin-left: 1.5vw;">{{$store.state.user.languageFlag ? top3Value[2].contry: top3Value[2].contryEn}}</div>
					<div style="flex: 2;">{{ top3Value[2].gener }}kW·h</div>
					<div style="flex: 1.4;">{{ top3Value[2].consumer }}kW·h</div>
				</div>
			</div>
		</div>
	</div> 

	<!-- 中间视图 -->

	<div style="width: 44.9vw;height: 81.7vh;padding-right: 0.8vw;">
		<div style="width: 44.9vw;height: 5.2vh;position: relative;">
			<img src="../assets/img/selectBox.png" alt="" class="selectBoxClose">
			<el-select v-model="valueArea" :placeholder="$store.state.user.languageFlag ? '国家' : 'Country'" :popper-append-to-body="false" @change="chooseArea" :no-data-text="$store.state.user.languageFlag ? '无数据' : 'Empty'">
				<el-option
				v-for="item in optionsArea"
				:key="$store.state.user.languageFlag ? item.areaName : item.areaEnName"
				:label="$store.state.user.languageFlag ? item.areaName : item.areaEnName"
				:value="item.areaName">
				</el-option>
			</el-select>

			<img src="../assets/img/selectBox.png" alt="" class="selectBoxClose" style="left: 13.33vw;">
			<el-select v-model="valueProvinces" :placeholder="valueArea ? $store.state.user.languageFlag ? valueArea : valueAreaEN : $store.state.user.languageFlag ? '省份' : 'Province'" :popper-append-to-body="false" style="left: 13.61vw;" :no-data-text="$store.state.user.languageFlag ? '无数据' : 'Empty'" @change="chooseProvinces">
				<el-option
				v-for="item in optionsProvinces"
				:key="$store.state.user.languageFlag ? item.areaName : item.areaEnName"
				:label="$store.state.user.languageFlag ? item.areaName : item.areaEnName"
				:value="item.areaName">
				</el-option>
			</el-select>
			
			 <div class="returnWorldBtn" v-if="valueArea !=''" @click="clearMapSelect">{{$store.state.user.languageFlag ? '返回': 'return'}}</div>
		</div>
		<div ref='initCenter' style="width: 44.9vw;height: 76.5vh;" @mouseleave="mouseleave"></div>
	</div>
	
	<!-- 右边视图 -->

	<div style="width: 23.5vw;height: 81.7vh;" class="rightBox">
		<div class="leftBoxTitle" style="margin-bottom: 2.6vh;">
			<div>{{$store.state.user.languageFlag ? '能量统计图' : 'Energy Chart'}}</div>
			<div style="white-space: nowrap;">
				<span :class="energyChartsType == 0 ? 'dateGetBtn' : 'dateLostBtn'" @click="changeChartsType(0)">{{$store.state.user.languageFlag ? '时' : 'H'}}</span>
				<span :class="energyChartsType == 1 ? 'dateGetBtn' : 'dateLostBtn'" @click="changeChartsType(1)">{{$store.state.user.languageFlag ? '日' : 'D'}}</span>
				<span :class="energyChartsType == 2 ? 'dateGetBtn' : 'dateLostBtn'" @click="changeChartsType(2)">{{$store.state.user.languageFlag ? '月' : 'M'}}</span>
				<span :class="energyChartsType == 3 ? 'dateGetBtn' : 'dateLostBtn'" @click="changeChartsType(3)">{{$store.state.user.languageFlag ? '年' : 'Y'}}</span>
			</div>
		</div>
		<div class="lineChart" ref='initFirstRight'></div>
		<div class="lineChart" ref='initSecondRight'></div>
		<div class="lineChart" ref='initThirdRight'></div>
	</div>
</div>
</template>

<script>
	import * as echarts from "echarts";
	import json from "../assets/json/world.json";
	import {getSnNum,getTopThree,getArea,getOnlineSnByArea,getHourData,getDayData,getMonthData,getYearData,getEqualsData} from '../api/data.js'
	
	export default {
	data () {
		return {
			chartData:[
			],
			onlineData: [
				{ value: 0, name: this.$store.state.user.languageFlag ? '在线' : 'Online' },
				{ value: 0, name: this.$store.state.user.languageFlag ? '故障' : 'Fault' },
				{ value: 0, name: this.$store.state.user.languageFlag ? '离线' : 'Offline' },
			],
			top3Type:1,
			top3Value:[
				{
					"gener": 0,       //发电量
					"consumer": 0,   //耗电量
					"contry": "暂无",
					"contryEn": ""
				},
				{
					"gener": 0,       //发电量
					"consumer": 0,   //耗电量
					"contry": "暂无",
					"contryEn": ""
				},
				{
					"gener": 0,       //发电量
					"consumer": 0,   //耗电量
					"contry": "暂无",
					"contryEn": ""
				}
			],
			energyStatisticsType:1,
			energyStatisticsValue:[
				{
					"gener": 0,       //发电量
					"consumer": 0,   //耗电量
					"contry": "暂无",
					"contryEn": ""
				},
				{
					"gener": 0,       //发电量
					"consumer": 0,   //耗电量
					"contry": "暂无",
					"contryEn": ""
				},
				{
					"gener": 0,       //发电量
					"consumer": 0,   //耗电量
					"contry": "暂无",
					"contryEn": ""
				}
			],
			mapSelectInit:false,
			arr:[0,-1],
			// 国家列表
			optionsArea: [],
			// 对应国家的省份列表
			optionsProvinces: [],
			// 选中国家
			valueArea: '',
			// 选中国家的EN
			valueAreaEN: '',
			// 选中省份
			valueProvinces: '',
			// 选定地区类型
			queryType:0,
			// 能量统计数据
			energyDate:{
				currentEs: 0,
				currentGrid: 0,
				currentHome: 0,
				currentPv: 0,
				es: 0,
				grid: 0,
				home: 0,
				pv: 0,
			},
			energyChartsType:0,
			energyChartsData:{},
			dx:0,
			dy:0,
		}
	},
	props:{
		msg:{
		type:Object,
		default:() => {}
		}
	},
	computed:{
        Obj(){
            return this.$store.state.user.languageFlag
        }
    },
	watch:{
		msg:{
			deep: true,
			handler(val) {
				this.getWorld(val)
			}
		},
		Obj(newVal,oldVal){
            //可以对数据执行相应的操作
			var _this = this
			_this.valueArea = ''
			_this.valueAreaEN = ''
			_this.valueProvinces = ''
			_this.optionsProvinces = []
			_this.queryType = 0
			echarts.registerMap("WORLD", json);
			this.loadTop3()
			getArea().then (res=>{
				console.log(res,'success');
				_this.optionsArea = res.data.data.areas
			}).catch(err=>{
				console.log(err,'error');
			})

			getSnNum().then (res=>{
				console.log(res,'success');
				_this.onlineData=[
					{ value: res.data.data.online, name: this.$store.state.user.languageFlag ? '在线' : 'Online' },
					{ value: res.data.data.errorNum, name: this.$store.state.user.languageFlag ? '故障' : 'Fault' },
					{ value: res.data.data.offline, name: this.$store.state.user.languageFlag ? '离线' : 'Offline' },
				]
				_this.initVariety()
			}).catch(err=>{
				console.log(err,'error');
			})

			getOnlineSnByArea().then (res=>{
				console.log(res,'success');
				_this.chartData = res.data.data.areaNums
				if (_this.$store.state.user.languageFlag) {
					for (let index = 0; index < _this.chartData.length; index++) {
						const element = _this.chartData[index];
						element.name=element.areaName
						element.value=element.areaNum
					}
				}else {
					for (let index = 0; index < _this.chartData.length; index++) {
						const element = _this.chartData[index];
						element.name=element.areaEnName
						element.value=element.areaNum
					}
				}
				_this.initData(this.chartData);
			}).catch(err=>{
				console.log(err,'error');
			})

			this.$nextTick(() => {
				this.getEqualsData();
				this.getChartsData();
			});

        }

	},
	mounted () {
		var _this = this
		echarts.registerMap("WORLD", json);
		this.loadTop3()
		getArea().then (res=>{
			console.log(res,'success');
			_this.optionsArea = res.data.data.areas
		}).catch(err=>{
			console.log(err,'error');
		})

		getSnNum().then (res=>{
			console.log(res,'success');
			_this.onlineData=[
				{ value: res.data.data.online, name: this.$store.state.user.languageFlag ? '在线' : 'Online' },
				{ value: res.data.data.errorNum, name: this.$store.state.user.languageFlag ? '故障' : 'Fault' },
				{ value: res.data.data.offline, name: this.$store.state.user.languageFlag ? '离线' : 'Offline' },
			]
			_this.initVariety()
		}).catch(err=>{
			console.log(err,'error');
		})

		getOnlineSnByArea().then (res=>{
			console.log(res,'success');
			_this.chartData = res.data.data.areaNums
			if (_this.$store.state.user.languageFlag) {
				for (let index = 0; index < _this.chartData.length; index++) {
					const element = _this.chartData[index];
					element.name=element.areaName
					element.value=element.areaNum
				}
			}else {
				for (let index = 0; index < _this.chartData.length; index++) {
					const element = _this.chartData[index];
					element.name=element.areaEnName
					element.value=element.areaNum
				}
			}
			_this.initData(this.chartData);
		}).catch(err=>{
			console.log(err,'error');
		})

		this.$nextTick(() => {
			this.getEqualsData();
			this.getChartsData();
		});

	},
	methods: {
		mouseleave(){
			this.initData(this.chartData);
		},
		// 渲染echarts:
		initDataLine1(){
			var _this = this
			echarts.init(this.$refs.initFirstRight).setOption({
			title: {
				show: true,
				top: 8,
				left: 10,
				text: `{a|}  ${ this.$store.state.user.languageFlag ? '太阳能曲线' : 'Solar'}`,
				// text: '太阳能曲线',
				// left: '4%',
				textStyle: {
					//字体风格,'normal','italic','oblique'
					fontStyle: 'normal',
					//字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
					fontWeight: 'normal',
					//字体系列
					fontFamily: 'san-serif',
					//字体大小
					fontSize: 12,
					lineHeight:20,
					verticalAlign: 'center',
					color:'#44BBFF',
					rich: {
						"a": {
							backgroundColor: {
								//在这里填写背景图片路径
								image: 'http://lksimg.yidaoo.cn//image/2023-05-15/2351515505963913.jpg'
							},
							//设置图片宽高
							height:20,
							width:20,
							verticalAlign: 'center',
							// lineHeight:20,
							// padding: [0, 0, 0, 0]
						}
					}
				}
			},
			backgroundColor:'#1C2137',
			tooltip: {
				trigger: "item",
				align: 'center',
				backgroundColor: 'transparent',
				textStyle : {
					color:'#fff',
					decoration: 'none',
					fontStyle: 'italic',
					fontWeight: 'bold'
				},
				formatter: function (params) {
					return `${params.name}</br>${ _this.$store.state.user.languageFlag ? '功率' : 'Power'}：${
						params.value > 0 ? params.value : 0
					}kW·h`;
				},
			},
			xAxis: {
				type: 'category',
				data: _this.energyChartsData.xlabel,
				axisLine: {
					// show: true,
					lineStyle: {
						color: "#82828E",
						width: 0.26
					},
				},
				boundaryGap: false, // 不留白，从原点开始
				// axisLine: {//x轴线设置
				// 	lineStyle: {
				// 		type: 'solid',
				// 		color: '#f0f0f0',//x线的颜色
				// 		width:'1'//坐标线的宽度
				// 	}
				// },
				// axisLabel: {//x轴字体颜色
				// 	textStyle: {
				// 		color: '#949494',
				// 	}
				// },
				axisTick:{//去掉x轴刻度
					show: false
				}  
			},
			grid:{
				show: true,
				borderColor: '#82828E',
				borderWidth: 0.26,
				bottom: 30,
				top: 62,
				left: 50,
				right: 40
			},
			yAxis: {
				// name: '单位: kW * h',
				// nameTextStyle: {
				// //  单位样式
				// color: '#82828E', //  字体颜色
				// },
				type: 'value',
				axisLine: {
					// show: false,
					lineStyle: {
						color: "#82828E",
						width: 0.26
					},
				},
				splitLine: {
					show: true,
					lineStyle: {
						// 设置背景横线
						// type:'dotted',
						color: "#82828E",
						width: 0.26
					}
				},
				splitArea : {show :false},//去除网格区域
				axisTick:{//去掉Y轴刻度
					show: false
				}

			},
			legend: {
				data: ['PV'],
				top: 32,
				right:35,
				textStyle:{
					color:'#fff'
				}
			},
			series: [
				{
				name:'PV',
				data: _this.energyChartsData.pv,
				// data: [218, 150, 230, 224, 218, 135, 147, 260],
				type: 'line',
				symbolSize: 8, //小圆点的大小
				lineStyle: {
					color: {
						type: 'linear',
						// x2=1,y=0,柱子的颜色在水平方向渐变
						x: 0,
						x2: 1,
						// color: "#e34d4e", //改变折线颜色
						colorStops: [
						// 0%处的颜色
						{
							offset: 0,
							color: '#476DFA',
						},
						// 100%处的颜色
						{
							offset: 1,
							color: '#77D1D6',
						},
						],}
					},
				itemStyle: {
					normal: {
						color: "#476DFA", //改变折线点的颜色
					},
				},
				smooth: true,
				areaStyle: {
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color: 'rgba(108,202,255,0.29)'
					},
					{
						offset: 1,
						color: 'rgba(104,190,240,0)'
					}
					])
				},
				}
			]
			});
		},
		initDataLine2(){
			var _this = this
			echarts.init(this.$refs.initSecondRight).setOption({
			title: {
				show: true,
				top: 8,
				left: 10,
				text: `{a|}  ${ _this.$store.state.user.languageFlag ? '家庭电网曲线图' : 'Home Grid'}`,
				// text: '太阳能曲线',
				// left: '4%',
				textStyle: {
					//字体风格,'normal','italic','oblique'
					fontStyle: 'normal',
					//字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
					fontWeight: 'normal',
					//字体系列
					fontFamily: 'san-serif',
					//字体大小
					fontSize: 12,
					lineHeight:20,
					verticalAlign: 'center',
					color:'#44BBFF',
					rich: {
						"a": {
							backgroundColor: {
								//在这里填写背景图片路径
								image: 'http://lksimg.yidaoo.cn//image/2023-05-15/2351515505963913.jpg'
							},
							//设置图片宽高
							height:20,
							width:20,
							verticalAlign: 'center',
							// lineHeight:20,
							// padding: [0, 0, 0, 0]
						}
					}
				}
			},
			backgroundColor:'#1C2137',
			tooltip: {
				trigger: "item",
				align: 'center',
				backgroundColor: 'transparent',
				textStyle : {
					color:'#fff',
					decoration: 'none',
					fontStyle: 'italic',
					fontWeight: 'bold'
				},
				formatter: function (params) {
				return `${params.name}</br>${ _this.$store.state.user.languageFlag ? '功率' : 'Power'}：${
					params.value > 0 ? params.value : 0
				}kW·h`;
				},
			},
			xAxis: {
				type: 'category',
				data: _this.energyChartsData.xlabel,
				boundaryGap: false,
				axisLine: {
					// show: true,
					lineStyle: {
						color: "#82828E",
						width: 0.26
					},
				},
				// axisLine: {//x轴线设置
				// 	lineStyle: {
				// 		type: 'solid',
				// 		color: '#f0f0f0',//x线的颜色
				// 		width:'1'//坐标线的宽度
				// 	}
				// },
				// axisLabel: {//x轴字体颜色
				// 	textStyle: {
				// 		color: '#949494',
				// 	}
				// },
				axisTick:{//去掉x轴刻度
					show: false
				}  
			},
			grid:{
				show: true,
				borderColor: '#82828E',
				borderWidth: 0.26,
				bottom: 30,
				top: 62,
				left: 50,
				right: 40
			},
			yAxis: {
				// name: '单位: kW * h',
				// nameTextStyle: {
				// //  单位样式
				// color: '#82828E', //  字体颜色
				// },
				type: 'value',
				axisLine: {
					// show: false,
					lineStyle: {
						color: "#82828E",
						width: 0.26
					},
				},
				splitLine: {
					show: true,
					lineStyle: {
						// 设置背景横线
						// type:'dotted',
						color: "#82828E",
						width: 0.26
					}
				},
				splitArea : {show :false},//去除网格区域
				axisTick:{//去掉Y轴刻度
					show: false
				}

			},
			legend: {
				data: ['Grid','Home','Essential'],
				top: 32,
				right:35,
				textStyle:{
					color:'#fff'
				}
			},
			series: [
				{
				name: 'Grid',
				data: _this.energyChartsData.grid,
				type: 'line',
				symbolSize: 8, //小圆点的大小
				lineStyle: {
					color: '#ba2663'
					// {
					// 	type: 'linear',
					// 	// x2=1,y=0,柱子的颜色在水平方向渐变
					// 	x: 0,
					// 	x2: 1,
					// 	// color: "#e34d4e", //改变折线颜色
					// 	colorStops: [
					// 	// 0%处的颜色
					// 	{
					// 		offset: 0,
					// 		color: '#476DFA',
					// 	},
					// 	// 100%处的颜色
					// 	{
					// 		offset: 1,
					// 		color: '#77D1D6',
					// 	},
					// 	],}
					},
				itemStyle: {
					normal: {
						color: "#476DFA", //改变折线点的颜色
					},
				},
				smooth: true,
				areaStyle: {
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color: 'rgba(108,202,255,0.29)'
					},
					{
						offset: 1,
						color: 'rgba(104,190,240,0)'
					}
					])
				},
				},
				{
				name:'Home',
				data: _this.energyChartsData.home,
				type: 'line',
				symbolSize: 8, //小圆点的大小
				lineStyle: {
					color: {
						type: 'linear',
						// x2=1,y=0,柱子的颜色在水平方向渐变
						x: 0,
						x2: 1,
						// color: "#e34d4e", //改变折线颜色
						colorStops: [
						// 0%处的颜色
						{
							offset: 0,
							color: '#476DFA',
						},
						// 100%处的颜色
						{
							offset: 1,
							color: '#77D1D6',
						},
						],}
					},
				itemStyle: {
					normal: {
						color: "#476DFA", //改变折线点的颜色
					},
				},
				smooth: true,
				areaStyle: {
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color: 'rgba(108,202,255,0.29)'
					},
					{
						offset: 1,
						color: 'rgba(104,190,240,0)'
					}
					])
				},
				},
				{
				name:'Essential',
				data: _this.energyChartsData.es,
				type: 'line',
				symbolSize: 8, //小圆点的大小
				lineStyle: {
					color: '#f2cd44'
					// {
					// 	type: 'linear',
					// 	// x2=1,y=0,柱子的颜色在水平方向渐变
					// 	x: 0,
					// 	x2: 1,
					// 	// color: "#e34d4e", //改变折线颜色
					// 	colorStops: [
					// 	// 0%处的颜色
					// 	{
					// 		offset: 0,
					// 		color: '#476DFA',
					// 	},
					// 	// 100%处的颜色
					// 	{
					// 		offset: 1,
					// 		color: '#77D1D6',
					// 	},
					// 	],}
					},
				itemStyle: {
					normal: {
						color: "#476DFA", //改变折线点的颜色
					},
				},
				smooth: true,
				areaStyle: {
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color: 'rgba(108,202,255,0.29)'
					},
					{
						offset: 1,
						color: 'rgba(104,190,240,0)'
					}
					])
				},
				},
			]
			});
		},
		initDataLine3(){
			var _this = this
			echarts.init(this.$refs.initThirdRight).setOption({
			title: {
				show: true,
				top: 8,
				left: 10,
				text: `{a|}  ${ _this.$store.state.user.languageFlag ? '电池曲线图' : 'Battery'}`,
				// text: '太阳能曲线',
				// left: '4%',
				textStyle: {
					//字体风格,'normal','italic','oblique'
					fontStyle: 'normal',
					//字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
					fontWeight: 'normal',
					//字体系列
					fontFamily: 'san-serif',
					//字体大小
					fontSize: 12,
					lineHeight:20,
					verticalAlign: 'center',
					color:'#44BBFF',
					rich: {
						"a": {
							backgroundColor: {
								//在这里填写背景图片路径
								image: 'http://lksimg.yidaoo.cn//image/2023-05-15/2351515505963913.jpg'
							},
							//设置图片宽高
							height:20,
							width:20,
							verticalAlign: 'center',
							// lineHeight:20,
							// padding: [0, 0, 0, 0]
						}
					}
				}
			},
			backgroundColor:'#1C2137',
			tooltip: {
				trigger: "item",
				align: 'center',
				backgroundColor: 'transparent',
				textStyle : {
					color:'#fff',
					decoration: 'none',
					fontStyle: 'italic',
					fontWeight: 'bold',
					fontSize: 10
				},
				formatter: function (params) {
					// console.log(params,'line');
					return `${params.name}</br>${ _this.$store.state.user.languageFlag ? '功率' : 'Power'}：${
						params.value > 0 ? params.value : 0
					}kW·h`;
				},
			},
			xAxis: {
				type: 'category',
				data: _this.energyChartsData.xlabel,
				boundaryGap: false,
				axisLine: {
					// show: true,
					lineStyle: {
						color: "#82828E",
						width: 0.26
					},
				},
				// axisLine: {//x轴线设置
				// 	lineStyle: {
				// 		type: 'solid',
				// 		color: '#f0f0f0',//x线的颜色
				// 		width:'1'//坐标线的宽度
				// 	}
				// },
				// axisLabel: {//x轴字体颜色
				// 	textStyle: {
				// 		color: '#949494',
				// 	}
				// },
				axisTick:{//去掉x轴刻度
					show: false
				}  
			},
			grid:{
				show: true,
				borderColor: '#82828E',
				borderWidth: 0.26,
				bottom: 30,
				top: 62,
				left: 50,
				right: 40
			},
			yAxis: {
				// name: '单位: kW * h',
				// nameTextStyle: {
				// //  单位样式
				// color: '#82828E', //  字体颜色
				// },
				type: 'value',
				axisLine: {
					// show: false,
					lineStyle: {
						color: "#82828E",
						width: 0.26
					},
				},
				splitLine: {
					show: true,
					lineStyle: {
						// 设置背景横线
						// type:'dotted',
						color: "#82828E",
						width: 0.26
					}
				},
				splitArea : {show :false},//去除网格区域
				axisTick:{//去掉Y轴刻度
					show: false
				}

			},
			legend: {
				data: ['Charge','Discharge'],
				top: 32,
				right:35,
				textStyle:{
					color:'#fff'
				}
			},
			series: [
				{
				name:'Charge',
				data: _this.energyChartsData.bat,
				type: 'line',
				symbolSize: 8, //小圆点的大小
				lineStyle: {
					color: '#84c1a0'
					// {
					// 	type: 'linear',
					// 	// x2=1,y=0,柱子的颜色在水平方向渐变
					// 	x: 0,
					// 	x2: 1,
					// 	// color: "#e34d4e", //改变折线颜色
					// 	colorStops: [
					// 	// 0%处的颜色
					// 	{
					// 		offset: 0,
					// 		color: '#476DFA',
					// 	},
					// 	// 100%处的颜色
					// 	{
					// 		offset: 1,
					// 		color: '#77D1D6',
					// 	},
					// 	],}
					},
				itemStyle: {
					normal: {
						color: "#476DFA", //改变折线点的颜色
					},
				},
				smooth: true,
				areaStyle: {
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color: 'rgba(108,202,255,0.29)'
					},
					{
						offset: 1,
						color: 'rgba(104,190,240,0)'
					}
					])
				},
				},
				{
				name:'Discharge',
				data: _this.energyChartsData.batf,
				type: 'line',
				symbolSize: 8, //小圆点的大小
				lineStyle: {
					color: {
						type: 'linear',
						// x2=1,y=0,柱子的颜色在水平方向渐变
						x: 0,
						x2: 1,
						// color: "#e34d4e", //改变折线颜色
						colorStops: [
						// 0%处的颜色
						{
							offset: 0,
							color: '#476DFA',
						},
						// 100%处的颜色
						{
							offset: 1,
							color: '#77D1D6',
						},
						],}
					},
				itemStyle: {
					normal: {
						color: "#476DFA", //改变折线点的颜色
					},
				},
				smooth: true,
				areaStyle: {
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color: 'rgba(108,202,255,0.29)'
					},
					{
						offset: 1,
						color: 'rgba(104,190,240,0)'
					}
					])
				},
				}
			]
			});
		},
		initVariety(){
			var _this = this
			echarts.init(this.$refs.initVariety).setOption({
				tooltip: {
					trigger: 'item',
					formatter:`{b}<br />{d}% <br /> {c}${ this.$store.state.user.languageFlag ? '台' : 'units'}`
				},
				color:['#37BAFF', '#FA5743', '#2F66EC'],
				legend: {
					
					orient: 'vertical',//设置图例的方向
					left: '0',
					top: "top",
					itemGap: 10,//设置图例的间距
					itemHeight: 5,
					itemWidth: 15,
					icon: "rect",// 图例项的icon,类型包括 circle(圆形),rect(正方形),
									//roundRect(圆角正方形),triangle(三角形),diamond(菱形),
									//pin(大头针行),arrow(箭头形),none(无图例项的icon)
					formatter: e => {
						var ratio = 0, value = 0;
						console.log(e);
						//通过e的内容,获取后台的百分比和车数
						if (_this.onlineData.length > 0) {
							_this.onlineData.forEach(item => {
								if (e == item.name) {
									value = item.value
								}
							});
						}
						//return `${e}      ${ratio}%       ${value}车`
						
						let arr = ['{a|' + e + ':}','{c|' + value + '}'] 
						//此处的a,b,c是textStyle里面配置的a,b,c,可以自定义
						return arr.join(' ')
					},
					textStyle: {//图例文字的样式
						rich: {
						a: {
							color:'#fff',
							align: 'left',
							fontSize:10
						},
						c:{
							color:'#fff',
							align: 'left',
							fontSize:10
						}
					}
					},
				},
				series: [{
					name: '我的账户',
					type: 'pie',
					radius: ['50%', '70%'],//设置圆环内外大小,饼图的半径,饼图的中心（圆心）坐标
					center: ["60%", "49%"],//设置饼图左右位置
					avoidLabelOverlap: false,
					// itemStyle: {
					// 	borderColor: '#fff',
					// 	borderWidth: 2
					// },
					label: {
						show: true,
						color:'#fff',
						fontSize: '13',
						hoverAnimation: false,
						// fontWeight: 'bold',
						formatter(param) {
						// correct the percentage
						return param.percent+'%';
						}
					},
					labelLine: {
						lineStyle:{
							color:'#fff',
							width: 0.4,
							type:'solid'
						},
					},
					emphasis: {
						label: {
						show: true,
						}
					},
					// labelLine: {
					// 	show: false
					// },
					data: _this.onlineData
					// data: that.varietyCountList
				}]
				});
		},
		initData(chartData) {
			var _this = this
			echarts.init(this.$refs.initCenter).setOption({
				// title: {
				//   text: "访问者IP地域分布",
				//   // subtext: 'access location',
				// },
				backgroundColor: 'transparent',
				tooltip: {
				trigger: "item",
				formatter: function (params) {
					return `${params.name}</br>${_this.$store.state.user.languageFlag ? '总设备数':'Number of devices'}：${
					params.value > 0 ? params.value : 0
					}`;
				},
				},
				toolbox: {
				show: true,
				// orient: 'vertical',
				left: "right",
				top: "top",
				// feature: {
				// 	dataView: {
				// 	readOnly: false,
				// 	title: "数据视图",
				// 	lang: ["数据视图", "关闭", "刷新"],
				// 	optionToContent: (opt) => {
				// 		let series = opt.series[0];
				// 		let msg = "国家    通联次数\n";
				// 		for (const item of series.data) {
				// 		msg += `${item.name}    ${item.value}\n`;
				// 		}
				// 		forEach(series.nameMap, (value, key) => {
				// 		// let count = 0;
				// 		// find(series.data, {name: key})
				// 		if (find(series.data, { name: key })) return;
				// 		msg += `${value}    ${0}\n`;
				// 		});
				// 		let textarea = `<textarea style="display: block; width: 100%; height: 100%; font-family: monospace; font-size: 14px; line-height: 1.6rem; resize: none; box-sizing: border-box; outline: none; color: rgb(0, 0, 0); border-color: rgb(51, 51, 51); background-color: rgb(255, 255, 255); position: relative; z-index: 999">${msg}</textarea>`;
				// 		return textarea;
				// 	},
				// 	onclick: function () {
				// 		showDesc.value = false;
				// 	},
				// 	},
				// 	restore: { show: false, title: "还原" },
				// 	saveAsImage: { title: "保存为图片" },
				// },
				},
				visualMap: {
				type: "continuous",
				// text: ["功率高", "功率低"],
				realtime: false,
				calculable: true,
				textStyle:{
					// fontSize: 18,//字体大小
					color: '#ffffff'//字体颜色
				},
				min: 1,
				max: 1000,
				formatter: function (value) {
					return value + `${_this.$store.state.user.languageFlag ? '台':'units'}`; // 范围标签显示内容。
				},
				inRange: {
					color: [
					"#227DD9",
					"#4575b4",
					"#74add1",
					"#abd9e9",
					"#e0f3f8",
					"#ffffbf",
					"#fee090",
					"#fdae61",
					"#f46d43",
					"#d73027",
					"#CA3437",
					],
				},
				},
				series: [
				{
                // 设置限制拖动范围
					type: "map",
					map: "WORLD",
					label: {
					// show: true,
					},
					zoom: 1.2,
					center: [0, 0],
					roam: true,
					scaleLimit: {
					min: 1.2,
					max: 20,
					},
					select: {
					// 点击后鼠标离开样式
					label: {
						color: "#333", // 子区域字体颜色
					},
					itemStyle: {
						areaColor: "#fff", 
						
					},
					},
					itemStyle: {
					normal: {//未选中状态
						borderColor:'#363E62',
						areaColor: '#363E62',
						shadowColor: "rgba(63, 218, 255, 0.5)",
						
					},
					emphasis: {// 也是选中样式
						borderColor:'#333',
						areaColor: '#fff',
						shadowColor: "rgba(63, 218, 255, 0.5)",
						shadowBlur: 100,//背景颜色// 上层地图面的颜色
						label: {
							show: true,
							textStyle: {
								color: '#333'
							}
						}
					},    
					focus: {// 也是选中样式
						borderColor:'#333',
						areaColor: '#fff',
						label: {
							show: true,
							textStyle: {
								color: '#333'
							}
						}
					},    
					},
					nameMap: _this.$store.state.user.languageFlag ? {
					"Singapore": "新加坡",
					"Dominican Rep.": "多米尼加",
					Palestine: "巴勒斯坦",
					Bahamas: "巴哈马",
					"Timor-Leste": "东帝汶",
					Afghanistan: "阿富汗",
					"Guinea-Bissau": "几内亚比绍",
					"Côte d'Ivoire": "科特迪瓦",
					"Siachen Glacier": "锡亚琴冰川",
					"Br. Indian Ocean Ter.": "英属印度洋领土",
					Angola: "安哥拉",
					Albania: "阿尔巴尼亚",
					"United Arab Emirates": "阿联酋",
					Argentina: "阿根廷",
					Armenia: "亚美尼亚",
					"French Southern and Antarctic Lands": "法属南半球和南极领地",
					Australia: "澳大利亚",
					Austria: "奥地利",
					Azerbaijan: "阿塞拜疆",
					Burundi: "布隆迪",
					Belgium: "比利时",
					Benin: "贝宁",
					"Burkina Faso": "布基纳法索",
					Bangladesh: "孟加拉国",
					Bulgaria: "保加利亚",
					"The Bahamas": "巴哈马",
					"Bosnia and Herz.": "波斯尼亚和黑塞哥维那",
					Belarus: "白俄罗斯",
					Belize: "伯利兹",
					Bermuda: "百慕大",
					Bolivia: "玻利维亚",
					Brazil: "巴西",
					Brunei: "文莱",
					Bhutan: "不丹",
					Botswana: "博茨瓦纳",
					"Central African Rep.": "中非",
					Canada: "加拿大",
					Switzerland: "瑞士",
					Chile: "智利",
					China: "中国",
					"Ivory Coast": "象牙海岸",
					Cameroon: "喀麦隆",
					"Dem. Rep. Congo": "刚果民主共和国",
					Congo: "刚果",
					Colombia: "哥伦比亚",
					"Costa Rica": "哥斯达黎加",
					Cuba: "古巴",
					"N. Cyprus": "北塞浦路斯",
					Cyprus: "塞浦路斯",
					"Czech Rep.": "捷克",
					Germany: "德国",
					Djibouti: "吉布提",
					Denmark: "丹麦",
					Algeria: "阿尔及利亚",
					Ecuador: "厄瓜多尔",
					Egypt: "埃及",
					Eritrea: "厄立特里亚",
					Spain: "西班牙",
					Estonia: "爱沙尼亚",
					Ethiopia: "埃塞俄比亚",
					Finland: "芬兰",
					Fiji: "斐",
					"Falkland Islands": "福克兰群岛",
					France: "法国",
					Gabon: "加蓬",
					"United Kingdom": "英国",
					Georgia: "格鲁吉亚",
					Ghana: "加纳",
					Guinea: "几内亚",
					Gambia: "冈比亚",
					"Guinea Bissau": "几内亚比绍",
					"Eq. Guinea": "赤道几内亚",
					Greece: "希腊",
					Greenland: "格陵兰",
					Guatemala: "危地马拉",
					"French Guiana": "法属圭亚那",
					Guyana: "圭亚那",
					Honduras: "洪都拉斯",
					Croatia: "克罗地亚",
					Haiti: "海地",
					Hungary: "匈牙利",
					Indonesia: "印度尼西亚",
					India: "印度",
					Ireland: "爱尔兰",
					Iran: "伊朗",
					Iraq: "伊拉克",
					Iceland: "冰岛",
					Israel: "以色列",
					Italy: "意大利",
					Jamaica: "牙买加",
					Jordan: "约旦",
					Japan: "日本",
					Kazakhstan: "哈萨克斯坦",
					Kenya: "肯尼亚",
					Kyrgyzstan: "吉尔吉斯斯坦",
					Cambodia: "柬埔寨",
					Korea: "韩国",
					Kosovo: "科索沃",
					Kuwait: "科威特",
					"Lao PDR": "老挝",
					Lebanon: "黎巴嫩",
					Liberia: "利比里亚",
					Libya: "利比亚",
					"Sri Lanka": "斯里兰卡",
					Lesotho: "莱索托",
					Lithuania: "立陶宛",
					Luxembourg: "卢森堡",
					Latvia: "拉脱维亚",
					Morocco: "摩洛哥",
					Moldova: "摩尔多瓦",
					Madagascar: "马达加斯加",
					Mexico: "墨西哥",
					Macedonia: "马其顿",
					Mali: "马里",
					Myanmar: "缅甸",
					Montenegro: "黑山",
					Mongolia: "蒙古",
					Mozambique: "莫桑比克",
					Mauritania: "毛里塔尼亚",
					Malawi: "马拉维",
					Malaysia: "马来西亚",
					Namibia: "纳米比亚",
					"New Caledonia": "新喀里多尼亚",
					Niger: "尼日尔",
					Nigeria: "尼日利亚",
					Nicaragua: "尼加拉瓜",
					Netherlands: "荷兰",
					Norway: "挪威",
					Nepal: "尼泊尔",
					"New Zealand": "新西兰",
					Oman: "阿曼",
					Pakistan: "巴基斯坦",
					Panama: "巴拿马",
					Peru: "秘鲁",
					Philippines: "菲律宾",
					"Papua New Guinea": "巴布亚新几内亚",
					Poland: "波兰",
					"Puerto Rico": "波多黎各",
					"Dem. Rep. Korea": "朝鲜",
					Portugal: "葡萄牙",
					Paraguay: "巴拉圭",
					Qatar: "卡塔尔",
					Romania: "罗马尼亚",
					Russia: "俄罗斯",
					Rwanda: "卢旺达",
					"W. Sahara": "西撒哈拉",
					"Saudi Arabia": "沙特阿拉伯",
					Sudan: "苏丹",
					"S. Sudan": "南苏丹",
					Senegal: "塞内加尔",
					"Solomon Is.": "所罗门群岛",
					"Sierra Leone": "塞拉利昂",
					"El Salvador": "萨尔瓦多",
					Somaliland: "索马里兰",
					Somalia: "索马里",
					Serbia: "塞尔维亚",
					Suriname: "苏里南",
					Slovakia: "斯洛伐克",
					Slovenia: "斯洛文尼亚",
					Sweden: "瑞典",
					Swaziland: "斯威士兰",
					Syria: "叙利亚",
					Chad: "乍得",
					Togo: "多哥",
					Thailand: "泰国",
					Tajikistan: "塔吉克斯坦",
					Turkmenistan: "土库曼斯坦",
					"East Timor": "东帝汶",
					"Trinidad and Tobago": "特里尼达和多巴哥",
					Tunisia: "突尼斯",
					Turkey: "土耳其",
					Tanzania: "坦桑尼亚",
					Uganda: "乌干达",
					Ukraine: "乌克兰",
					Uruguay: "乌拉圭",
					"United States": "美国",
					Uzbekistan: "乌兹别克斯坦",
					Venezuela: "委内瑞拉",
					Vietnam: "越南",
					Vanuatu: "瓦努阿图",
					"West Bank": "西岸",
					Yemen: "也门",
					"South Africa": "南非",
					Zambia: "赞比亚",
					Zimbabwe: "津巴布韦",
					Comoros: "科摩罗",
					}:'',
					data: chartData,
				},
				],
			});
			
			
			if(!this.mapSelectInit){
				echarts.init(this.$refs.initCenter).on("click", function (params) { //点击事件
					console.log('我点击了',params)
					let goalflag = false
					_this.queryType = 1,
					_this.valueProvinces = ''
					if (_this.$store.state.user.languageFlag) {
						_this.valueArea = params.name
					}else {
						_this.valueAreaEN = params.name
						_this.optionsArea.forEach(element => {
							if (element.areaEnName == _this.valueAreaEN) {
								_this.valueArea = element.areaName
								goalflag = true
							}
						});
					}
					_this.optionsArea.forEach(element => {
						if (_this.$store.state.user.languageFlag) {
							if (element.areaName == _this.valueArea) {
								_this.optionsProvinces = element.provinces
								goalflag = true
							}
						}else {
							if (element.areaEnName == _this.valueAreaEN) {
								_this.optionsProvinces = element.provinces
								goalflag = true
							}
						}
					});
					if (!goalflag) {
						_this.valueArea = params.name
						_this.optionsProvinces = []
					}
				})
				echarts.init(this.$refs.initCenter).on('selectchanged', function (params) {
					console.log(params.fromAction);
					if (params.fromAction == 'unselect') {
						setTimeout(() => {
							_this.valueArea = ''
							_this.valueAreaEN = ''
							_this.valueProvinces = ''
							_this.optionsProvinces = []
							_this.queryType = 0
							_this.getEqualsData()
							_this.getChartsData()
							console.log(_this.chartData)
						}, 1);
					}else {
						setTimeout(() => {
							_this.getEqualsData()
							_this.getChartsData()
							console.log(_this.chartData)
						}, 1);
					}
				});
				this.mapSelectInit = true
			}
			
			// var mapContainer = this.$refs.initCenter;
			// // 获取地图容器的位置和尺寸
			// var containerRect = mapContainer.getBoundingClientRect();

			// // 记录地图容器的边界
			// var containerLeft = containerRect.left;
			// var containerTop = containerRect.top;
			// var containerRight = containerRect.right;
			// var containerBottom = containerRect.bottom;

			// // 记录当前地图的位置
			// var mapLeft = 0;
			// var mapTop = 0;

			// // 监听地图的拖动事件
			// echarts.init(this.$refs.initCenter).on('geoRoam', function(params) {
			// 	console.log('params',params);
			// // 获取地图移动的偏移量
			// if (params.dx) {
			// 	_this.dx += params.dx;
			// 	_this.dy += params.dy;
			// }
			// console.log(_this.dx,_this.dy);
			// });
			
			},
		clearMapSelect(){
			var _this = this
			_this.valueArea = ''
			_this.valueAreaEN = ''
			_this.valueProvinces = ''
			_this.optionsProvinces = []
			_this.queryType = 0
			_this.getEqualsData()
			_this.getChartsData()
			setTimeout(() => {
				echarts.init(this.$refs.initCenter).clear();
				this.initData(this.chartData);
			}, 1);
		},
		initLeftBar() {
			var _this = this
			const option = {
				xAxis: {
					type: 'category',
					data: [`${_this.energyDate.currentHome}kW·h\n${ this.$store.state.user.languageFlag ? '家庭' : 'Home'}`, `${_this.energyDate.currentEs}kW·h\n${ this.$store.state.user.languageFlag ? '不间断' : 'Essential'}`],
					show:true,
					splitLine:{show: false},
					offset:2,
					axisLabel: {
						color: '#FFF',  
						fontSize: '9',
					},
					axisTick: {
						show: false,    // 是否显示坐标轴刻度
					},
					axisLine: {
						onZero:false,
						show: true,    // 是否显示坐标轴轴线
						// symbolSize: [10, 15],     // 轴线两端箭头大小，数值一表示宽度，数值二表示高度
						lineStyle: {
							color: {
								type: 'linear',
								x: 0,
								y: 0,
								x2: 1,
								y2: 0,
								colorStops: [{
									offset: 0, color: 'rgba(0, 251, 255, 1)' // 0% 处的颜色
								}, {
									offset: 1, color: 'rgba(0, 249, 255, 0)' // 100% 处的颜色
								}],
								global: false // 缺省为 false
							},    // 坐标轴线线的颜色
							width: '1',    // 坐标轴线线宽
							type: 'solid',    // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
						},
					},
				},
				yAxis: {
					
					type: 'value',
					
					name:`${this.$store.state.user.languageFlag ? '耗\n电\n量' : 'Consumption'}`,
					nameLocation:'middle',
					nameGap: this.$store.state.user.languageFlag ? 6 : 0,
					nameRotate:this.$store.state.user.languageFlag ? 0 : 90,
					nameTextStyle:{
						color:'#37BAFF',
						fontSize:10,
						width:11,
						lineHeight:17,
						overflow:'break'
					},
					// min : function(value){
					// 	//最小值设置，其中-value.max * 1.05 乘以1.05 ，主要是使图的边界小于最小的值，给边界留空间
					// 	return ( Math.abs(value.min) < value.max ? -value.max * 1.05 : value.min *  1.05).toFixed(2);
					// },
					// max : function(value){
					// //最大值设置，其中-value.min * 1.05 乘以1.05 ，主要是使图的边界大于最大于值，给边界留空间
					// 	return ( Math.abs(value.min) < value.max ? value.max * 1.05 : -value.min * 1.05).toFixed(2);
					// },
					show:true,
					// offset:2,
					splitLine:{show: false},
					axisTick: {
						show: false,    // 是否显示坐标轴刻度
					},
					axisLabel:{
						show:false, 
					},
					axisLine: {
						onZero:false,
						show: true,    // 是否显示坐标轴轴线
						lineStyle: {
							color: 
							{
								type: 'linear',
								x: 0,
								y: 1,
								x2: 0,
								y2: 0,
								colorStops: [{
									offset: 0, color: 'rgba(0, 251, 255, 1)' // 0% 处的颜色
								}, {
									offset: 1, color: 'rgba(0, 249, 255, 0)' // 100% 处的颜色
								}],
								global: false // 缺省为 false
							},    // 坐标轴线线的颜色
							width: '1',    // 坐标轴线线宽
							type: 'solid',    // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
							// dashOffset: 10,
						},
					},
				},
				grid: {
					x: 20,
					y: 40,
					x2: 0,
					y2: 40,
				},
				series: [
					{
						data: [
							{
								value: _this.energyDate.currentHome,
								itemStyle: {
									color: '#368ff0'
								}
							},
							{
								value: _this.energyDate.currentEs,
								itemStyle: {
									color: '#0FD4C8'
								}
							},
						],
						type: 'bar',
						showBackground: true,
						backgroundStyle: {
							color: '#363E62'
						},
						barWidth:'90%',
						label:{
							normal:{
								show: true,
								formatter:function(params){
									if (params.dataIndex == 0) {
										if (_this.energyDate.home>0) {
											return	`￪${_this.energyDate.home}\n{a|${_this.$store.state.user.languageFlag ? '同比上期':'contrast'}}`;
										}else {
											return	`￬${_this.energyDate.home*-1}\n{a|${_this.$store.state.user.languageFlag ? '同比上期':'contrast'}}`;
										}
									}else {
										if (_this.energyDate.es>0) {
											return	`￪${_this.energyDate.es}\n{a|${_this.$store.state.user.languageFlag ? '同比上期':'contrast'}}`;
										}else {
											return	`￬${_this.energyDate.es*-1}\n{a|${_this.$store.state.user.languageFlag ? '同比上期':'contrast'}}`;
										}
									}
								},
								textStyle: {
									color: '#fff', // 标签字体颜色
									fontSize: 18, // 标签字体大小
									fontWeight: 'bold', // 标签字体加粗
									fontFamily: 'Arial', // 标签字体
									align: 'center',
									
								},
								position: 'top',
								rich: {
									a: {
										fontSize: 10,
									}
								}
							},
							
						}
					}
				]
			};
			let  myChart = this.$echarts.init(this.$refs.initLeftBar);
			myChart.setOption(option);
		},
		initRightBar() {
			var _this = this
			const option = {
				xAxis: {
					type: 'category',
					data: [`${_this.energyDate.currentPv}kW·h\n${ this.$store.state.user.languageFlag ? '太阳能' : 'Pv'}`, `${_this.energyDate.currentGrid}kW·h\n${ this.$store.state.user.languageFlag ? '市⽹' : 'Grid'}`],
					show:true,
					splitLine:{show: false},
					offset:2,
					axisLabel: {
						color: '#FFF',  
						fontSize: '9',
					},
					axisTick: {
						show: false,    // 是否显示坐标轴刻度
					},
					axisLine: {
						onZero:false,
						show: true,    // 是否显示坐标轴轴线
						// symbolSize: [10, 15],     // 轴线两端箭头大小，数值一表示宽度，数值二表示高度
						lineStyle: {
							color: {
								type: 'linear',
								x: 0,
								y: 0,
								x2: 1,
								y2: 0,
								colorStops: [{
									offset: 0, color: 'rgba(0, 251, 255, 1)' // 0% 处的颜色
								}, {
									offset: 1, color: 'rgba(0, 249, 255, 0)' // 100% 处的颜色
								}],
								global: false // 缺省为 false
							},    // 坐标轴线线的颜色
							width: '1',    // 坐标轴线线宽
							type: 'solid',    // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
						},
					},
				},
				yAxis: {
					
					type: 'value',
					name:`${this.$store.state.user.languageFlag ? '发\n电\n量' : 'Generation'}`,
					nameLocation:'middle',
					nameGap: this.$store.state.user.languageFlag ? 6 : 0,
					nameRotate:this.$store.state.user.languageFlag ? 0 : 90,
					nameTextStyle:{
						color:'#37BAFF',
						fontSize:10,
						width:11,
						lineHeight:17,
						overflow:'break'
					},
					show:true,
					// offset:2,
					splitLine:{show: false},
					axisTick: {
						show: false,    // 是否显示坐标轴刻度
					},
					axisLabel:{
						show:false, 
					},
					axisLine: {
						onZero:false,
						show: true,    // 是否显示坐标轴轴线
						lineStyle: {
							color: 
							{
								type: 'linear',
								x: 0,
								y: 1,
								x2: 0,
								y2: 0,
								colorStops: [{
									offset: 0, color: 'rgba(0, 251, 255, 1)' // 0% 处的颜色
								}, {
									offset: 1, color: 'rgba(0, 249, 255, 0)' // 100% 处的颜色
								}],
								global: false // 缺省为 false
							},    // 坐标轴线线的颜色
							width: '1',    // 坐标轴线线宽
							type: 'solid',    // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
							// dashOffset: 10,
						},
					},
				},
				grid: {
					x: 20,
					y: 40,
					x2: 0,
					y2: 40,
				},
				series: [
					{
						data: [
							{
								value: _this.energyDate.currentPv,
								itemStyle: {
									color: '#368ff0'
								}
							},
							{
								value: _this.energyDate.currentGrid,
								itemStyle: {
									color: '#0FD4C8'
								}
							},
						],
						type: 'bar',
						showBackground: true,
						backgroundStyle: {
							color: '#363E62'
						},
						barWidth:'90%',
						label:{
							normal:{
								show: true,
								formatter:function(params){
									if (params.dataIndex == 0) {
										if (_this.energyDate.pv>0) {
											return	`￪${_this.energyDate.pv}\n{a|${_this.$store.state.user.languageFlag ? '同比上期':'contrast'}}`;
										}else {
											return	`￬${_this.energyDate.pv*-1}\n{a|${_this.$store.state.user.languageFlag ? '同比上期':'contrast'}}`;
										}
									}else {
										if (_this.energyDate.grid>0) {
											return	`￪${_this.energyDate.grid}\n{a|${_this.$store.state.user.languageFlag ? '同比上期':'contrast'}}`;
										}else {
											return	`￬${_this.energyDate.grid*-1}\n{a|${_this.$store.state.user.languageFlag ? '同比上期':'contrast'}}`;
										}
									}
								},
								position: 'top',
								textStyle: {
									color: '#fff', // 标签字体颜色
									fontSize: 18, // 标签字体大小
									fontWeight: 'bold', // 标签字体加粗
									fontFamily: 'Arial', // 标签字体
									align: 'center',
									
								},
								rich: {
									a: {
										fontSize: 10,
									}
								}
							},
							
						}
					}
				]
			};
			let  myChart = this.$echarts.init(this.$refs.initRightBar);
			myChart.setOption(option);
		},
		changeType(e){
			this.top3Type = e
			this.loadTop3()
		},
		loadTop3() {
			var _this = this
			getTopThree({
				timeType:_this.top3Type
			}).then (res=>{
				console.log(res,'success');
				let list = res.data.data.generAndConsumer
				if (list.length == 0) {
					_this.top3Value = [
						{
							"gener": 0,       //发电量
							"consumer": 0,   //耗电量
							"contry": "暂无",
							"contryEn": "None"
						},
						{
							"gener": 0,       //发电量
							"consumer": 0,   //耗电量
							"contry": "暂无",
							"contryEn": "None"
						},
						{
							"gener": 0,       //发电量
							"consumer": 0,   //耗电量
							"contry": "暂无",
							"contryEn": "None"
						}
					]
				}else if (list.length == 1) {
					_this.top3Value = [
						list[0],
						{
							"gener": 0,       //发电量
							"consumer": 0,   //耗电量
							"contry": "暂无",
							"contryEn": "None"
						},
						{
							"gener": 0,       //发电量
							"consumer": 0,   //耗电量
							"contry": "暂无",
							"contryEn": "None"
						}
					]
				}else if (list.length == 2) {
					_this.top3Value = [
						list[0],
						list[1],
						{
							"gener": 0,       //发电量
							"consumer": 0,   //耗电量
							"contry": "暂无",
							"contryEn": "None"
						}
					]
				}else {
					_this.top3Value = list
				}
				_this.top3Value.forEach(element => {
					element.gener = element.gener / 10
					element.consumer = element.consumer / 10
				});
			}).catch(err=>{
				console.log(err,'error');
			})
		},
		changeEnergyType(e){
			this.energyStatisticsType = e
			this.getEqualsData()
		},
		getEqualsData() {
			var _this = this
			let area = ''
			if (_this.queryType == 1) {
				area = _this.valueArea
			}
			if (_this.queryType == 2) {
				area = _this.valueProvinces
			}
			getEqualsData({
				timeType:_this.energyStatisticsType,
				queryType : _this.queryType,
				area : area,
			}).then (res=>{
				let list = res.data.data
				console.log(list,'successlist');
				_this.energyDate = list
				for (const key in _this.energyDate) {
					if (Object.hasOwnProperty.call(_this.energyDate, key)) {
						_this.energyDate[key] /=10;
						
					}
				}
				_this.initLeftBar()
				_this.initRightBar()
			}).catch(err=>{
				console.log(err,'error');
			})
		},
		chooseArea() {
			var _this = this
			let goalflag = false
			this.valueProvinces = ''
			_this.queryType = 1,
			this.getEqualsData()
			this.getChartsData()
			this.optionsArea.forEach(element => {
				if (element.areaName == _this.valueArea) {
					_this.optionsProvinces = element.provinces
					_this.valueAreaEN = element.areaEnName
					goalflag = true
				}
			});
			if (!goalflag) {
				_this.optionsProvinces = []
			}
		},
		chooseProvinces() {
			var _this = this
			_this.queryType = 2
			this.getEqualsData()
			this.getChartsData()
		},
		changeChartsType(e){
			this.energyChartsType = e
			this.getChartsData()
		},
		getChartsData(){
			var _this = this
			let area = ''
			if (_this.queryType == 1) {
				area = _this.valueArea
			}
			if (_this.queryType == 2) {
				area = _this.valueProvinces
			}
			var timeType = this.energyChartsType
			if (timeType == 0) {
				getHourData({
					queryType : _this.queryType,
					area : area,
				}).then (res=>{
					_this.energyChartsData = res.data.data
					_this.energyChartsData.xlabel = []
					for (let index = 0; index < _this.energyChartsData.pv.length; index++) {
						_this.energyChartsData.xlabel.push(index+':00')
					}
					_this.initDataLine1();
					_this.initDataLine2();
					_this.initDataLine3();
				}).catch(err=>{
					console.log(err,'error');
				})
			}else if (timeType == 1) {
				getDayData({
					queryType : _this.queryType,
					area : area,
				}).then (res=>{
					_this.energyChartsData = res.data.data
					_this.energyChartsData.xlabel = []
					for (let index = 0; index < _this.energyChartsData.pv.length; index++) {
						_this.energyChartsData.xlabel.push(index+1)
					}
					for (let index = 0; index < _this.energyChartsData.bat.length; index++) {
						_this.energyChartsData.bat[index] = _this.energyChartsData.bat[index] /10
						
					}
					for (let index = 0; index < _this.energyChartsData.pv.length; index++) {
						_this.energyChartsData.pv[index] = _this.energyChartsData.pv[index] /10
						
					}
					for (let index = 0; index < _this.energyChartsData.grid.length; index++) {
						_this.energyChartsData.grid[index] = _this.energyChartsData.grid[index] /10
						
					}
					for (let index = 0; index < _this.energyChartsData.es.length; index++) {
						_this.energyChartsData.es[index] = _this.energyChartsData.es[index] /10
						
					}
					for (let index = 0; index < _this.energyChartsData.home.length; index++) {
						_this.energyChartsData.home[index] = _this.energyChartsData.home[index] /10
						
					}
					for (let index = 0; index < _this.energyChartsData.batf.length; index++) {
						_this.energyChartsData.batf[index] = _this.energyChartsData.batf[index] /10
						
					}
					_this.initDataLine1();
					_this.initDataLine2();
					_this.initDataLine3();
				}).catch(err=>{
					console.log(err,'error');
				})
			}else if (timeType == 2) {
				getMonthData({
					queryType : _this.queryType,
					area : area,
				}).then (res=>{
					_this.energyChartsData = res.data.data
					var months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
					_this.energyChartsData.xlabel = []
					for (let index = 0; index < _this.energyChartsData.pv.length; index++) {
						_this.energyChartsData.xlabel.push(months[index])
					}
					for (let index = 0; index < _this.energyChartsData.bat.length; index++) {
						_this.energyChartsData.bat[index] = _this.energyChartsData.bat[index] /10
						
					}
					for (let index = 0; index < _this.energyChartsData.pv.length; index++) {
						_this.energyChartsData.pv[index] = _this.energyChartsData.pv[index] /10
						
					}
					for (let index = 0; index < _this.energyChartsData.grid.length; index++) {
						_this.energyChartsData.grid[index] = _this.energyChartsData.grid[index] /10
						
					}
					for (let index = 0; index < _this.energyChartsData.es.length; index++) {
						_this.energyChartsData.es[index] = _this.energyChartsData.es[index] /10
						
					}
					for (let index = 0; index < _this.energyChartsData.home.length; index++) {
						_this.energyChartsData.home[index] = _this.energyChartsData.home[index] /10
						
					}
					for (let index = 0; index < _this.energyChartsData.batf.length; index++) {
						_this.energyChartsData.batf[index] = _this.energyChartsData.batf[index] /10
						
					}
					_this.initDataLine1();
					_this.initDataLine2();
					_this.initDataLine3();
				}).catch(err=>{
					console.log(err,'error');
				})
			}else if (timeType == 3) {
				getYearData({
					queryType : _this.queryType,
					area : area,
				}).then (res=>{
					_this.energyChartsData = res.data.data
					_this.energyChartsData.xlabel = []
					for (let index = 0; index < _this.energyChartsData.pv.length; index++) {
						_this.energyChartsData.xlabel.push(index+_this.energyChartsData.startDate)
					}
					for (let index = 0; index < _this.energyChartsData.bat.length; index++) {
						_this.energyChartsData.bat[index] = _this.energyChartsData.bat[index] /10
						
					}
					for (let index = 0; index < _this.energyChartsData.pv.length; index++) {
						_this.energyChartsData.pv[index] = _this.energyChartsData.pv[index] /10
						
					}
					for (let index = 0; index < _this.energyChartsData.grid.length; index++) {
						_this.energyChartsData.grid[index] = _this.energyChartsData.grid[index] /10
						
					}
					for (let index = 0; index < _this.energyChartsData.es.length; index++) {
						_this.energyChartsData.es[index] = _this.energyChartsData.es[index] /10
						
					}
					for (let index = 0; index < _this.energyChartsData.home.length; index++) {
						_this.energyChartsData.home[index] = _this.energyChartsData.home[index] /10
						
					}
					for (let index = 0; index < _this.energyChartsData.batf.length; index++) {
						_this.energyChartsData.batf[index] = _this.energyChartsData.batf[index] /10
						
					}
					console.log(_this.energyChartsData);
					_this.initDataLine1();
					_this.initDataLine2();
					_this.initDataLine3();
				}).catch(err=>{
					console.log(err,'error');
				})
			}
			
		}
	}
	}
</script>
<style scoped>
.wrapper {
	width: 100%;
	/* min-width: 1786px; */
	height: 100%;
	/* min-height: 800px; */
	background-image: url(../assets/img/mainBg.jpg);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	padding-top: 3vh;
	box-sizing: border-box;
}
.wrapper .chart {
	width: 80%;
	margin:0 auto;
	height: 600px;
	border: 1px solid #eeeeee;
	/* background: url(../../public/static/bg.png) no-repeat; 背景图设置*/ 
	background-size: 100% 100%;
}
.leftBox {
	width: 22.7vw;
	height: 26vh;
	background: #2D3351;
	box-shadow: 0px 2px 8px 0px #13172B;
	border-radius: 18px;
	border: 2px solid #444B6B;
	margin-bottom: 18px;
	box-sizing: border-box;
	padding: 18px 20px 0;
}
.rightBox {
	background: #2D3351;
	box-shadow: 0px 2px 8px 0px #13172B;
	border-radius: 18px;
	border: 2px solid #444B6B;
	margin-bottom: 18px;
	box-sizing: border-box;
	padding: 18px 20px 0;
}
.leftBoxTitle {
	display: flex;
	color: #fff;
	height: 2.5vh;
	line-height: 2.5vh;
	font-size: 1.8vh;
	justify-content: space-between;
	padding: 0 5px;
}

.dateGetBtn {
	padding: 0 9px;
	height: 2.5vh;
	background-color: #363E62;
	display: inline-block;
	margin-left: 10px;
	border-radius: 5px;
	font-size: 1.4vh;
	cursor: pointer;
}

.dateLostBtn {
	padding: 0 9px;
	height: 2.5vh;
	background-color: #252839;
	display: inline-block;
	margin-left: 10px;
	border-radius: 5px;
	font-size: 1.4vh;
	color: #696D7F;
	cursor: pointer;
}

.lineChart {
	width: 20.1vw;
	height: 22.5vh;
	margin: 0 auto;
	margin-bottom: 2.32vh;
	border-radius: 3px;
	overflow: hidden;
	box-sizing: content-box;
}

.topTable {
	margin-top: 3.5vh;	
}

.tableTitle {
	display: flex;
	text-align: center;
	color: #44BBFF;
	line-height: 1.58vh;
	font-size: 1.11vh;
	margin-bottom: 1.85vh;
}
.tableline {
	display: flex;
	text-align: center;
	color: #44BBFF;
	height: 2.6vh;
	line-height: 2.6vh;
	font-size: 1.11vh;
	margin-bottom: 1.39vh;
	background: linear-gradient(90deg, rgba(7,57,59,0) 0%, rgba(89,195,255,0.27) 49%, rgba(9,67,70,0) 100%);
	border: 1px solid;
	border-image: linear-gradient(90deg,rgba(53, 241, 249, 0), rgba(68, 187, 255, 1), rgba(151, 151, 151, 0)) 1 1;
	position: relative;
}

.topIcon {
	position: absolute;
	height: 2.23vh;
	top: 0.15vh;
}

.lableLine {
	width: 6.67vw;
	height: 15.48vh;
	border: 1px solid;
	border-image: linear-gradient(130deg, rgba(0, 253, 255, 0), rgba(0, 251, 255, 1), rgba(0, 249, 255, 0)) 1 1;
	border-top: none;
	border-right: none;
}

.yTitle {
	color: #37BAFF;
	font-size: 1.11vh;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	writing-mode: vertical-rl;
	width: 1.5vw;
	letter-spacing: 0.4vh;
}
::v-deep .el-input--suffix .el-input__inner {
  margin: 0;
  width: 6.7vw;
  height: 2.13vh;
  color: #37BAFF;
  line-height: 2.13vh ;
  font-size: 1.11vh;
  padding-left: 0;
}

::v-deep input::-webkit-input-placeholder {
	color: #37BAFF;
}

::v-deep .el-select {
	line-height: 2.13vh;
	position: absolute;
	top: 1.8vh;
	left: 3.61vw;
}

/* // 设置输入框的背景色、字体颜色、边框属性设置； */

::v-deep .el-input__inner {
  background-color: #2c3352;
  color: #37BAFF;
  border: none;
  border-radius: 0;
  text-align: right;
  font-weight: 700;
  /* padding: 0 ; */
}

::v-deep .el-input__icon{
    line-height: 2.13vh ;
}

::v-deep .el-icon-arrow-up:before {
    /* content: "\e6e1"; */
	content: '';
	display: inline-block;
	margin-bottom: 0.15vh;
	width: 0.7vw;
	height: 0.8vh;
	background-image: url(../assets/img/pullIcon.png);
	background-size: 100% 100%;
}
::v-deep .el-popper .popper__arrow {
  display: none;
}
::v-deep .el-popper .popper__arrow::after {
  display: none;
}

::v-deep .el-select-dropdown {
	background-color: #252b44;
	border-radius: 0;
	border: none;
	width: 0.7vw;
	left:0 !important;
}

.el-select-dropdown__item {
	color: #fff !important;
	font-size: 1.11vh;
	text-align: right;
	padding: 0 10px;
 	/* line-height: 19px; */
}

::v-deep .el-popper[x-placement^="bottom"] {
  margin-top: 0px;
}

::v-deep .el-select-dropdown__list {
	max-height: 15vh;
}

.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
    background-color: rgba(0, 225, 219, 0.690196078431373);
}

.selectBoxClose {
	position: absolute;
	width: 7.2vw;
	height: 3.5vh;
	top: 1vh;
	left: 3.33vw;
}
.returnWorldBtn{
	font-size: 0.729vw;
    padding: 0 1vw;
    height: 3.25vh;
    vertical-align: middle;
    border-radius: 8px;
    color: #37BAFF;
    cursor: pointer;
    line-height: 3.25vh;;
    text-align: center;
    display: inline-block;
    background-color: #202435;
    position: absolute;
    top: 1.5vh;
}
</style>