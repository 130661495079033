import router from "./index";

export function getChildrenPath () {
    let rou = {
        path: 'device', name: 'device', component: function () {
            return import('../views/deviceAssignment.vue')
        }
    }
    const parentRoute = router.options.routes.find(route => route.path === '/')
    parentRoute.children.push(rou) // 将占位符路由替换为新路由
    router.addRoutes([parentRoute]) // 动态添加路由
}